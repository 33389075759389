<template>
  <div class="organization">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="100px">
      <el-form-item label="举办城市：">
        <cascader-area
          :country="queryInfo.country"
          :province="queryInfo.province"
          :city="queryInfo.city"
          @searchCountry="searchCountry"
          @searchProvince="searchProvince"
          @searchCity="searchCity"
        />
      </el-form-item>
      <el-form-item label="机构类型：">
        <dic-radio-button
          :code.sync="queryInfo.institutionsType"
          type-code="036"
          query-name="institutionsType"
          name="机构类型"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item,index) in tags"
          :key="index+item.name"
          @close="closeTag(index,item.tagName)"
        >
          {{item.name}}：{{queryInfo[item.tagName]?queryInfo[item.tagName]:'不限'}}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength')&&item.valLength>0"
          >+{{item.valLength}}</span>
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="inputVal">
        <el-input placeholder="请输入论文机构" v-model="queryInfo.queryConditions" class="lager-input">
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="organizationTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        :default-sort="{prop: 'date', order: 'descending'}"
        @sort-change="sortChange"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column prop="institutionsNameEng" label="所属机构(英文)" align="center" />
        <el-table-column prop="institutionsNameCha" label="所属机构(中文)" align="center" />
        <el-table-column prop="count" label="作者个数" align="center" sortable/>
        <el-table-column prop="institutionsCount" label="论文个数" align="center" sortable="custom" />
        <el-table-column prop="institutionsType" label="机构类型" align="center" />
        <el-table-column label="所在地区" align="center">
          <template v-slot="{row}">
            {{row.country?row.country:''}}
            {{row.province?'-'+row.province:''}}
            {{row.city?'-'+row.city:''}}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import CascaderArea from '@/components/CascaderArea.vue'
import ButtonTable from '@/components/ButtonTable.vue'
import DicRadioButton from '@/components/DicRadioButton.vue'
import { pagination } from '@/mixins/pagination'
import { getOrganization } from '@/api/paper'
export default {
  name: 'Organization',

  components: {
    ButtonTable,
    CascaderArea,
    DicRadioButton,
  },

  mixins: [pagination],

  data() {
    return {
      queryInfo: {
        columnName: "update_time",
        pageNum: 1,
        pageSize: 10,
        order:1,
        institutionsType: '', // 机构类型
        country: '', // 国家
        province: '', // 省份
        city: '', // 城市
        queryConditions: '', // 查询条件
      },
      organizationTable: [], // 表格数据
      tags: [
        {
          name: '机构类型',
          tagName: 'institutionsType',
        },
      ],
    }
  },

  created() {
    this.search()
  },

  methods: {
    async search() {
      const res = await getOrganization(this.queryInfo)
      if (res.code === 200) {
        this.organizationTable = res.data.list

        this.total = res.data.total
      }
    },

    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      if (!this.tags.some((item) => item.tagName === queryName)) {
        this.tags.push({
          name,
          tagName: queryName,
        })
      }

      // 拿到name在tags的下标
      let result
      result = this.tags.findIndex((el) => {
        return el.name === name
      })

      this.search()
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1)

      this.queryInfo[name] = ''

      this.search()
    },

    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "count") {
        this.queryInfo.columnName = "count";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      if (column.prop === "institutionsCount") {
        this.queryInfo.columnName = "institutionsCount";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      this.search();
    },
    searchCountry(val) {
      this.queryInfo.country = val
      this.search()
    },
    searchProvince(val) {
      this.queryInfo.province = val
      this.search()
    },
    searchCity(val) {
      this.queryInfo.city = val
      this.search()
    },
  },
}
</script>

<style lang="less" scoped>
.organization {
  .inputVal {
    display: flex;
    justify-content: flex-end;
    /deep/ .el-input-group__append {
      background: #1e9fff;
      color: #fff;
      border: 1px solid #1e9fff;
    }
    .caretTime:hover {
      cursor: pointer;
    }
  }
}
</style>
